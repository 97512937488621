<template>
  <nav
    class="row"
    :class="$attrs.class">
    <div
      class="appbar col-md-6 col-sm-12 mb-4"
      :class="{
        'bg-primary border-primary': isBgPrimary,
        'bg-transparent': isBgTransparent,
        'border-bottom': withBorderBottom
      }">
      <div class="d-flex align-items-center">
        <router-link
          :to="previousLink"
          class="btn-back pe-3 text-decoration-none pt-2 text-gray-1"
          :class="{
            'd-md-none': noBackButtonOnDesktop,
            'text-white': isBgPrimary || isBgTransparent
          }"
        >
          <i class="ri-arrow-left-line ri-xl fw-500"></i>
        </router-link>

        <h1 class="fw-600 fs-14 lh-28 mb-0" :class="{ 'text-white': isBgPrimary || isBgTransparent }">{{ title }}</h1>

        <!-- <button
          class="btn btn-search d-md-none lh-0">
          <i class="ri-error-warning-fill ri-xl fw-500"></i>
        </button> -->

        <button
          class="btn btn-search d-md-none"
          v-if="withSearch"
          @click="searchExpanded = !searchExpanded">
          <i v-if="!searchExpanded" class="ri-search-line"></i>
          <i v-else class="ri-close-line"></i>
        </button>
      </div>
      <section
        v-if="withSearch && searchExpanded"
        class="nav-search">
        <input
          type="text"
          class="form-control"
          id="app-bar-search"
          :placeholder="searchPlaceholder || 'Cari..'"
          @keyup="onKeyUp($event.target.value)"
          :value="modelValue">
      </section>
    </div>
  </nav>

  <!-- divider 😂 -->
  <div
    v-if="!noDivider"
    class="row mt-5 mt-md-0 pt-2 pt-md-0" :class="{ 'bg-primary': isBgPrimary }"></div>

</template>

<script>
import { nextTick, ref, watch } from 'vue'

export default {
  props: {
    title: {
      type: String
    },
    previousLink: {
      type: [Object, String],
      default: '#'
    },
    noBackButtonOnDesktop: {
      type: Boolean,
      default: false
    },
    noDivider: {
      type: Boolean,
      default: false
    },
    withSearch: {
      type: Boolean,
      default: false
    },
    withBorderBottom: {
      type: Boolean,
      default: true
    },
    isBgPrimary: {
      type: Boolean,
      default: false
    },
    isBgTransparent: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: { type: String, default: '' },
    modelValue: { type: String, default: '' }
  },
  setup (props, { emit }) {
    const searchExpanded = ref(false)

    watch(searchExpanded, () => {
      if (searchExpanded.value) {
        nextTick(() => {
          document.querySelector('#app-bar-search').focus()
        })
      } else {
        emit('update:modelValue', '')
        emit('search')
      }
    })

    let timeout = null
    const onKeyUp = value => {
      emit('update:modelValue', value)

      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        emit('search')
      }, 300)
    }

    return {
      searchExpanded,
      onKeyUp
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  &:not(.sub) {
    padding-top: 2%;
  }

  .appbar {
    background-color: white;

    @media (max-width: 520px) {
      z-index: 99;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 11px 18px;
    }

    .border-bottom {
      border-bottom:  1px solid rgb(240, 240, 240);
    }

    h1 {
      @media (max-width: 520px) {
        margin: auto;
      }
    }

    @media (max-width: 520px) {
      .btn-back {
        position: absolute;
      }

      .btn-search {
        position: absolute;
        right: .5rem;
      }

      .nav-search {
        position: absolute;
        left: .7rem;
        right: 4rem;
        top: 50%;
        transform: translateY(-50%);

        input {
          padding: .7rem 1rem;
        }
      }
    }
  }
}
</style>
