<template>
    <div class="main-page">
      <div class="container">
        <div class="d-md-none">
          <app-bar
            title="Atur Kata Sandi"
            :previousLink="{ name: 'Login' }"/>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 d-flex align-items-center vh-md-100 px-3">
                <div class="card forgot-password p-0 p-md-5 border-0 w-100">
                    <div class="card-body p-0">
                      <div class="group-title mb-4">
                            <span class="fs-24 fw-700 color-neutral-900 d-block">Atur Kata Sandi</span>
                            <span class="fw-normal fs-12 color-neutral-600">Silahkan gunakan password yang Kami kirimkan ke email Anda.</span>
                        </div>
                        <Form
                          @submit="submit"
                          v-slot="{ errors }"
                          class="px-md-5">
                          <label>Kata Sandi</label>
                            <div class="input-group mb-3">
                                <Field
                                    :type="type"
                                    v-model="password"
                                    name="password"
                                    rules="required"
                                    class="form-control"
                                    :class="{ 'is-invalid': errors.password || errorMessage }"
                                    id="password-visibility"
                                    :disabled="loading.login"
                                    placeholder="Kata Sandi" />
                                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.password || errorMessage }">
                                    <div class="show-password" type="button" @click="type = showPassword(type)" :class="{'opacity-0':!password}">
                                        <i class="ri-eye-fill fs-24" v-if="type === 'password'"></i>
                                        <i class="ri-eye-off-fill fs-24" v-if="type === 'text'"></i>
                                    </div>
                                </span>
                                <span v-if="!errors.password" class="invalid-feedback">{{ errorMessage }}</span>
                                <ErrorMessage name="password" class="invalid-feedback" />
                            </div>
                          <button type="submit" class="btn btn-primary w-100 btn-login p-md-3" :disabled="loading.login">
                              <p class="fst-normal fw-600 mb-0">
                                {{ (loading.login) ? 'Proses Masuk..':'Masuk' }}
                              </p>
                          </button>
                          <div class="d-flex flex-column justify-content-center align-items-center mt-3">
                            <div class="text-center">
                              <span class="d-block mb-3 fs-14 fw-400 color-neutral-600">{{ (loading.forgotPassword) ? 'Mengirim ulang..':'Belum menerima password ?' }}</span>
                              <span v-if="countDown > 0" class="fs-14 fw-700 color-neutral-900">{{ countDown }}</span>
                              <a v-else-if="!loading.forgotPassword" href="#" class="fs-14 fw-700 color-secondary-500" @click="resend">
                                Kirim Ulang
                              </a>
                              <span v-else class="spinner-border align-middle" role="status"></span>
                            </div>
                          </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { login, showPassword, loading } from '@/core/auth'
import AppBar from '@/components/AppBar.vue'
import axios from '@/plugins/axios'

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    AppBar
  },
  setup () {
    const router = useRouter()
    const email = useRoute().params.email
    const type = ref('password')
    const password = ref('')
    const errorMessage = ref('')
    const countDown = ref(90)

    const countDownTimer = () => {
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1
          countDownTimer()
        }, 1000)
      }
    }

    const submit = async () => {
      try {
        loading.login = true
        const { status, message } = await login({
          email: email,
          password: password.value,
          token_login: null // sementara null aja dulu
        })

        // Jika sukses
        if (status) {
          // redirect ke halaman sukses dulu
          return router.push({ name: 'SettingPasswordDone' })
        }

        errorMessage.value = message
      } catch (error) {
        errorMessage.value = error.response.data.message
      } finally {
        loading.login = false
      }
    }

    const resend = async () => {
      try {
        loading.forgotPassword = true
        const result = await axios.post('app/lupa_password', {
          email: email
        })

        if (!result.status) {
          errorMessage.value = result.message
        }

        // Reset timer
        countDown.value = 90
        countDownTimer()
      } catch (error) {
        errorMessage.value = error.response.data.message
      } finally {
        loading.forgotPassword = false
      }
    }

    onMounted(() => {
      countDownTimer()
    })

    return {
      type,
      password,
      showPassword,
      submit,
      loading,
      errorMessage,
      resend,
      countDown
    }
  }
}
</script>

<style lang="scss" scoped>
.card.forgot-password {
    border-radius: 10px;

    @media (min-width: 520px) {
      box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
    }
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.btn-login {
  border: none;
  padding: 10px 20px;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  animation-duration: 1.5s
}
</style>
